const FIREBASE_DEVELOPMENT = {
  apiKey: 'AIzaSyDdKWQTki4uCsRF857uUH931FS_--BqKx8',
  authDomain: 'zoko-web2-test.firebaseapp.com',
  databaseURL: 'https://zoko-web2-test.firebaseio.com',
  projectId: 'zoko-web2-test',
  storageBucket: 'zoko-web2-test.appspot.com',
  messagingSenderId: '949195383124',
  appId: '1:949195383124:web:c8eaddea6bf50b04d0a8c1',
  measurementId: 'G-JR9H5KZ1LW',
}

const FIREBASE_PRODUCTION = {
  apiKey: 'AIzaSyDYW5jlZHM07EkMF_1K7H3ewncKaRB2Mgs',
  authDomain: 'zoko-web-prod.firebaseapp.com',
  databaseURL: 'https://zoko-web-prod.firebaseio.com',
  projectId: 'zoko-web-prod',
  storageBucket: 'zoko-web-prod.appspot.com',
  messagingSenderId: '30512584928',
  appId: '1:30512584928:web:92b38083f30e4155',
}

export { FIREBASE_DEVELOPMENT, FIREBASE_PRODUCTION }
