import { useRoutePropagation } from '@shopify/app-bridge-react'
import React from 'react'
import { useHistory } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import routes from 'lib/config/routes'

const ShopifyEntry = React.lazy(() => import('./modules/Shopify/Entry'))
const ShopifyExit = React.lazy(() => import('./modules/Shopify/Exit'))
const Shopify = React.lazy(() => import('./modules/Shopify/Main'))

const Routes = () => {
  const history = useHistory()

  useRoutePropagation(history.location)

  return (
    <Switch>
      <Route
        exact
        path={routes.SHOPIFY_ENTRY}
        render={(props) => <ShopifyEntry {...props} />}
      />
      <Route
        exact
        path={routes.SHOPIFY_EXIT}
        render={(props) => <ShopifyExit {...props} />}
      />
      <Route
        exact
        path={routes.SHOPIFY_ACCOUNT}
        render={(props) => <ShopifyEntry {...props} />}
      />
      <Route
        exact
        path={routes.SHOPIFY_SETTINGS}
        render={(props) => <Shopify {...props} />}
      />
    </Switch>
  )
}

export default Routes
