import { FIREBASE_DEVELOPMENT, FIREBASE_PRODUCTION } from './firebaseConfigs'

const ENV = process.env.REACT_APP_HOST_ENV || 'development'

const development = {
  SHOPIFY_API_KEY: 'b4be3b2f0799dad51d2eec3baef84b62',
  SHOPIFY_BASE_URI: 'salesapp.dev.zoko.io',
  SHOPIFY_REDIRECT_URI: 'https://salesapp.dev.zoko.io/exit',
  SHOPIFY_SCOPES:
    'read_product_listings,read_products,write_orders,write_checkouts,write_resource_feedbacks,unauthenticated_read_product_listings,unauthenticated_read_product_tags,unauthenticated_write_checkouts,unauthenticated_write_customers,unauthenticated_read_customer_tags,unauthenticated_read_content',
  WEB_API: 'https://test.management.zoko.io',
  WEB_URL: 'https://app.dev.zoko.io',
  FIREBASE: FIREBASE_DEVELOPMENT,
}

const production = {
  ...development,
  SHOPIFY_API_KEY: '37658a4f71f01fa3d2c0b794cc7fdfde',
  SHOPIFY_BASE_URI: 'salesapp.live.zoko.io',
  SHOPIFY_REDIRECT_URI: 'https://salesapp.live.zoko.io/exit',
  SHOPIFY_SCOPES:
    'read_product_listings,read_products,write_orders,write_checkouts,write_resource_feedbacks,unauthenticated_read_product_listings,unauthenticated_read_product_tags,unauthenticated_write_checkouts,unauthenticated_write_customers,unauthenticated_read_customer_tags,unauthenticated_read_content',
  WEB_API: 'https://web3.api.zoko.io/v3',
  WEB_URL: 'https://app.live.zoko.io',
  FIREBASE: FIREBASE_PRODUCTION,
}

const configs = {
  development,
  production,
}

const currentConfig = configs[ENV]

const config = {
  SHOPIFY_SERVICE_URL: currentConfig.SHOPIFY_SERVICE,
  SHOPIFY_BASE_URI: currentConfig.SHOPIFY_BASE_URI,
  SHOPIFY_API_KEY: currentConfig.SHOPIFY_API_KEY,
  SHOPIFY_SCOPES: currentConfig.SHOPIFY_SCOPES,
  SHOPIFY_REDIRECT_URI: currentConfig.SHOPIFY_REDIRECT_URI,
  WEB_API: currentConfig.WEB_API,
  WEB_URL: currentConfig.WEB_URL,
  FIREBASE: currentConfig.FIREBASE,
}

export default config
