import { Provider } from '@shopify/app-bridge-react'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'

import config from 'lib/config'

import AppSkeleton from './App.shopifySkeleton'
import Routes from './Router'
import { ThemeProvider } from './common/contexts/themeContext/themeContext'

const App = () => {
  const { search } = useLocation()
  const { host } = parse(search)

  const configParams = {
    apiKey: config.SHOPIFY_API_KEY,
    host: host ? host : Buffer.from(config.SHOPIFY_BASE_URI).toString('base64'),
    forceRedirect: false,
  }
  window.console.log('shop params -> ', configParams)

  return (
    <ThemeProvider>
      <AppProvider i18n={translations}>
        <Provider config={configParams}>
          <Suspense fallback={<AppSkeleton />}>
            <Routes />
          </Suspense>
        </Provider>
      </AppProvider>
    </ThemeProvider>
  )
}

App.propTypes = {
  location: PropTypes.object,
}

export default App
