const BASE_URL = ''

const routes = {
  SHOPIFY_ACCOUNT: BASE_URL + '/',
  SHOPIFY_ENTRY: BASE_URL + '/entry',
  SHOPIFY_EXIT: BASE_URL + '/exit',
  SHOPIFY_SETTINGS: BASE_URL + '/settings',
  ZOKO_AUTH: BASE_URL + '/login',
}

export default routes
